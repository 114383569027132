export default {
  pt: {
    alignment: 'Alinhamento',
    centralize: 'Centralizar',
    centralize_horizontally: 'Centralizar horizontalmente',
    centralize_vertically: 'Centralizar verticalmente',
    align_bottom: 'Alinhar à base',
    align_top: 'Alinhar ao topo',
    align_right: 'Alinhar à direita',
    align_left: 'Alinhar à esquerda',
    align_horizontally: 'Alinhar horizontalmente',
    align_vertically: 'Alinhar verticalmente',
    distribution: 'Distribuição',
    vertical_distribution: 'Distribuição vertical',
    horizontal_distribution: 'Distribuição horizontal',
    distribute_horizontal_left: 'Lados esquerdos à mesma distância',
    distribute_horizontal_center: 'Centros à mesma distância',
    distribute_horizontal_right: 'Lados direitos à mesma distância',
    distribute_horizontal_gap: 'Distância horizontal igualmente',
    distribute_vertical_top: 'Topos à mesma distância',
    distribute_vertical_center: 'Centros à mesma distância',
    distribute_vertical_bottom: 'Bases à mesma distância',
    distribute_vertical_gap: 'Distância vertical igualmente',
    same_height: 'Mesma altura',
    same_width: 'Mesma largura',
    same_size: 'Mesmo tamanho',
    bring_to_front: "Para frente",
    bring_forward: "Para o topo",
    send_backward: "Para trás",
    send_to_back: "Para o fundo",
    order: "Ordem"
  },
  en: {
    alignment: 'Alignment',
    centralize: 'Centralize',
    centralize_horizontally: 'Centralize horizontally',
    centralize_vertically: 'Centralize vertically',
    align_bottom: 'Align to bottom',
    align_top: 'Align to top',
    align_right: 'Align to right',
    align_left: 'Align to left',
    align_horizontally: 'Align horizontally',
    align_vertically: 'Align vertically',
    distribution: 'Distribution',
    vertical_distribution: 'Vertical distribution',
    horizontal_distribution: 'Horizontal distribution',
    distribute_horizontal_left: 'Left sides evenly',
    distribute_horizontal_center: 'Horizontal centers evenly',
    distribute_horizontal_right: 'Right sides evenly',
    distribute_horizontal_gap: 'Horizontal distance evenly',
    distribute_vertical_top: 'Top sides evenly',
    distribute_vertical_center: 'Vertical centers evenly',
    distribute_vertical_bottom: 'Bottom sides evenly',
    distribute_vertical_gap: 'Vertical distance evenly',
    same_height: 'Same height',
    same_width: 'Same width',
    same_size: 'Same size',
    bring_to_front: "Bring to front",
    bring_forward: "Bring forward",
    send_backward: "Send backward",
    send_to_back: "Send to back",
    order: "Order"
  }
};