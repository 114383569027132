<template>
  <div class="control" :style="style">
    <div
      class="ruler"
      v-if="control.synopticComponent.fill != 'none' && intervalList.length"
    >
      <template v-for="(item, ix) in intervalList">
        <div
          v-if="item.marker && item.marker.enabled"
          :key="ix"
          class="ruler-tab"
          :style="{left: `${colorPosition[ix]}%`}"
        >
          <div :style="markerDashStyle(item)"></div>
          <div
            :style="{
              'margin-top': item.marker.icon.offsetY,
              'margin-left': item.marker.icon.offsetX
            }"
          >
            <i
              class="ruler-mark"
              :class="item.marker.icon.class"
              :style="markerIconStyle(item)"
            ></i>
          </div>
          <div :style="markerTextStyle(item)">
            <span class="ruler-mark">
              <DataValueSpan
                v-if="markerTextValue(item)"
                :entry="markerTextValue(item)"
              />
            </span>
          </div>
        </div>
      </template>
    </div>
    <div class="progressbar" :style="progressBarStyle">
      <div :style="offsetStyle">
        <div :style="leftStyle"></div>
      </div>
      <div class="progress" :style="rightStyle" ref="progress"></div>
      <div class="text" :style="textStyle" v-if="showLabel" ref="label">
        <div class="status-label">
          <div :style="labelStyle">
            <span v-html="formattedValue"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SynopticDataValueBase from "./synoptic-data-value-base.vue";
import DataValueSpan from "@/components/data-value-span.vue";

import Controls from "@/assets/dashboard/controls.json";
const defaultItervalItem = function () {
  let control = Controls.find(
    (item) =>
      item.template.synopticComponent.componentName == "SynopticProgressBar"
  );
  let item = control
    ? control.template.synopticComponent.interval.items[0]
    : null;
  return JSON.parse(JSON.stringify(item));
};

export default {
  name: "SynopticProgressBar",
  extends: SynopticDataValueBase,
  components: {
    DataValueSpan
  },
  props: {
    control: Object
  },
  computed: {
    isDirty() {
      return false;
    },
    mode() {
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    perc() {
      return this.calcPerc(this.rawValue);
    },
    offsetStyle() {
      let perc = this.perc;
      let r = JSON.parse(JSON.stringify(this.size));
      r["margin-left"] = -1 * (100 - perc) + "%";
      r["padding-left"] = 100 - perc + "%";
      r["overflow"] = "hidden";
      return r;
    },
    leftStyle() {
      let lst = [];
      let perc = this.perc;
      let r = JSON.parse(JSON.stringify(this.size));
      if (this.fillType == "none" || this.intervalList.length == 0) {
        r["background-color"] = this.control.synopticComponent.barColor;
      } else if (this.fillType == "single") {
        let ix = this.colorPosition.findIndex((pos) => perc <= pos);
        if (ix >= 0 && ix < this.colorPosition.length) {
          r["background-color"] = this.intervalList[ix].color;
        } else {
          r["background-color"] = this.control.synopticComponent.barColor;
        }
      } else if (this.fillType == "gradient") {
        lst = this.intervalList.map((i) => i.color);
        r["background-image"] = `linear-gradient(to right, ${lst.join(",")})`;
      } else if (this.fillType == "piled") {
        let pos = 0;
        this.intervalList.forEach((item, i) => {
          if (i > 0) {
            lst.push(`${this.intervalList[i - 1].color} ${pos.toFixed(2)}%`);
          }
          lst.push(`${item.color} ${pos.toFixed(2)}%`);
          pos = this.colorPosition[i];
        });
        r["background-image"] = `linear-gradient(to right, ${lst.join(",")})`;
      }
      return r;
    },
    rightStyle() {
      let r = {
        "background-color":
          (this?.control?.synopticComponent?.style &&
            this.control.synopticComponent.style["background-color"]) ||
          "#FFFFFF"
      };
      r.left = `${this.perc}%`;
      r.transition = this.mode == "editor" ? "left 0s" : "left 1s";
      return r;
    },
    rotation() {
      return this?.control?.synopticComponent?.rotation || 0;
    },
    size() {
      return {
        height: this.currentRect.height + "px",
        width: this.currentRect.width + "px"
      };
    },
    colorMarkers() {
      return this.intervalList.filter((item) => item.marker.enabled);
    },
    intervalList() {
      const v = this.itemValue;
      return [
        ...(this?.control?.synopticComponent?.interval?.items || [])
      ].sort((a, b) => (v(a) > v(b) ? 1 : v(b) > v(a) ? -1 : 0));
    },
    result() {
      const type =
        this.min === undefined ||
        this.max === undefined ||
        this?.control?.synopticComponent?.interval?.source == "percentage"
          ? "percentage"
          : "constant";
      return {
        type: type,
        min: this.min ?? 0,
        max: this.max ?? 100,
        value:
          type == "percentage"
            ? this.calcPerc(this.rawValue, 0, 100)
            : parseFloat(this.rawValue)
      };
    },
    colorPosition() {
      return this.intervalList.map((item) => {
        return this.calcPerc(
          this.itemValue(item),
          parseFloat(this.result.type == "percentage" ? 0 : this.result.min),
          parseFloat(this.result.type == "percentage" ? 100 : this.result.max)
        );
      });
    },
    fillType() {
      // none, single, piled, gradient
      return this?.control?.synopticComponent.fill || "none";
    },
    defaultColor() {
      return this?.control?.synopticComponent?.style?.color || "#3F51B5";
    },
    textStyle() {
      let fill = this?.control?.synopticComponent.fill || "none";
      let barSize =
        this?.control?.synopticComponent.clientRect["width"] *
        (this.perc / 100);
      let fgColor =
        (this?.control?.synopticComponent?.style &&
          this.control.synopticComponent.style["color"]) ||
        "#3F51B5";
      let r = {
        width: `${this.perc}%`
      };
      if (barSize < 56) {
        if (this.value > 0) {
          r["left"] = barSize + "px";
        } else {
          r["width"] = "100%";
        }
        r["color"] = fgColor;
      } else {
        if (fill == "piled" || fill == "gradient") {
          r["color"] = fgColor;
        } else {
          r["color"] = fgColor;
        }
      }
      return r;
    },
    textRotationStyle() {
      if (this.rotation) {
        return {
          transform: `rotate(${360 - this.rotation}deg)`
        };
      } else {
        return {};
      }
    },
    labelStyle() {
      // TODO: implement the text style
      return {...{}, ...this.textRotationStyle};
    },
    showLabel() {
      return this?.control?.format !== "";
    },
    dataEntry() {
      let entry = {
        ...this.lastData,
        perc: this.perc,
        ...{value: this?.lastData?.current_value?.value}
      };
      if (this.showLabel) {
        if ((this?.control?.format || "").indexOf("|") >= 0) {
          entry.template = this?.control?.format;
        } else {
          if ((this?.control?.format || "").indexOf("${") >= 0) {
            entry.template = this?.control?.format;
          } else {
            entry.template =
              "${data.current_value.value} | " + this?.control?.format;
          }
        }
      }
      return entry;
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    },
    progressBarStyle() {
      return {
        "border-radius": (this?.controlStyle || {})["border-radius"] || "10px"
      };
    }
  },
  methods: {
    markerTextStyle(item) {
      // TODO: implement the text style
      if (!item.marker.value.show) return {display: "none"};
      let r = {
        ...{
          color: item.marker.value.color,
          "margin-top": item.marker.value.offsetY,
          "margin-left": item.marker.value.offsetX,
          "white-space": "nowrap"
        },
        ...this.textRotationStyle
      };

      return r;
    },
    markerTextValue(item) {
      // item.marker.value.format = "%d";
      let fmt = item.marker.value.format;
      if (fmt) {
        let value = this.itemValue(item);
        if (fmt.indexOf("%") == -1) {
          value = fmt;
          fmt = "%s";
        }
        let entry = {
          current_value: {
            value: value
          }
        };
        if (fmt.match(/(\$\{|%)/g)) {
          entry.template = "${data.current_value.value}|" + fmt;
        }
        return entry;
      }
      return null;
    },
    markerIconStyle(item) {
      if (!item.marker.icon.show) return {visibility: "hidden"};
      let r = {
        color: item.marker.icon.color,
        transform: `rotate(${360 - item.marker.icon.rotation}deg)`
      };
      return r;
    },
    markerDashStyle(item) {
      let w = parseFloat(item.marker.dash.width.replace(/px/, ""));
      let r = {
        height: this.size.height,
        width: w + "px",
        "margin-left": -1 * (w / 2) + "px",
        "border-left":
          (item.marker.dash.show ? w + "px" : 0) +
          " solid " +
          item.marker.dash.color,
        "box-shadow":
          item.marker.dash.show && item.marker.dash.shadow
            ? "0px 0px 3px #666"
            : "none"
      };
      return r;
    },
    itemValue(item) {
      let vlr = this.limit(item);
      vlr = parseFloat(vlr ?? item.value);
      return vlr;
    },
    migrate() {
      // backward compatibility:
      // migrates the control json definition to the latest one
      if (this?.control?.synopticComponent?.interval) return;
      let synopticComponent = JSON.parse(
        JSON.stringify(this.control.synopticComponent)
      );
      let interval = {
        source: "percentage",
        items: []
      };
      let perc = 0;
      (synopticComponent.colors || []).forEach((color, ix) => {
        let item = defaultItervalItem();
        let size = 0;
        if (synopticComponent.fill == "gradient") {
          size = Math.round(100 / synopticComponent.colors.length);
        } else {
          size = (synopticComponent.colorSize || [])[ix] || 100;
        }
        perc += size;
        item.value = perc;
        item.color = color;
        interval.items.push(item);
      });

      if (synopticComponent.vertical) {
        //this.$set(synopticComponent, "rotation", 270);
        synopticComponent.rotation = 270;
        let w = synopticComponent.clientRect.width;
        let h = synopticComponent.clientRect.height;
        synopticComponent.clientRect.width = h;
        synopticComponent.clientRect.height = w;
      }
      synopticComponent.interval = interval;
      if (synopticComponent.fill == "single") {
        synopticComponent.barColor = synopticComponent.style.color;
        synopticComponent.fill = "none";
      } else if (synopticComponent.fill == "solid")
        synopticComponent.fill = "piled";
      delete synopticComponent.vertical;
      delete synopticComponent.colors;
      delete synopticComponent.colorSize;
      this.$set(this.control, "synopticComponent", synopticComponent);
    }
  },
  mounted: function () {
    this.$emit("hasContent", true);
  },
  created() {
    this.migrate();
  }
};
</script>

<style scoped>
.control {
  box-sizing: content-box;
}
.progressbar {
  position: relative;
  overflow: hidden;
  /* border-radius: 10px; */
  width: 100%;
  z-index: inherit;
}

.progress {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  transition: width 0.5s ease-in 0s;
}

.status-label {
  display: table;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.status-label > div {
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  white-space: pre;
}

.ruler {
  line-height: 16px;
  position: relative;
  z-index: 1;
}

.ruler-tab {
  position: absolute;
  width: 16px;
}

.ruler-mark {
  margin-left: -16px;
}
</style>
