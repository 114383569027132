<template>
  <button
    class="value editable btn-default"
    :contenteditable="mode == 'editor' ? true : false"
    ref="edit"
    :style="inputStyle"
    :disabled="!userHasInteractionPermission"
    v-html="text"
  />
</template>

<script>
export default {
  name: "SynopticButton",
  data() {
    return {
      hasFocus: false
    };
  },
  props: {
    control: Object,
    currentRect: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  methods: {
    setFocus(value) {
      this.hasFocus = value;
    }
  },
  computed: {
    mode() {
      //return this.$store.getters['dashboard/mode']||'viewer';
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    text: {
      set(value) {
        this.control.synopticComponent.value = value;
      },
      get() {
        let value =
          this?.control?.synopticComponent?.tmp?.text ||
          this?.control?.synopticComponent?.value ||
          "";
        return value.replace(/\n/g, "<br/>").replace(/\\n/g, "<br/>"); // legacy
      }
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    inputStyle() {
      // right now input and select are sharing same style, but keep it independent
      let r = Object.assign(
        {
          "font-size": "14pt",
          "background-color": "transparent",
          color: "#000",
          border: "1px solid gray",
          "border-radius": "3px",
          "text-align": "center"
        },
        this.controlStyle
      );
      r["width"] = this.currentRect.width + "px";
      r["height"] = this.currentRect.height + "px";
      r["white-space"] = "normal"; // parent control outer border
      if (this.tmp && this.tmp.style) {
        Object.assign(r, this.tmp.style);
      }
      return r;
    },
    controlStyle() {
      return {
        ...this.control.synopticComponent.style,
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation
        ) || 0}deg)`
      };
    },
    userHasInteractionPermission() {
      return (
        !this.control.synopticComponent.interaction_permission?.length ||
        this.control.synopticComponent.interaction_permission.some((p) =>
          this.$store.getters["user/hasUserAccessTo"](p)
        )
      );
    }
  },
  mounted: function() {
    let self = this;
    this.$emit("hasContent", true);
    $(this.$refs.edit).on("focus", function(e) {
      e.stopPropagation();
      self.hasFocus = true;
      const $this = $(this);
      e.target.focus();
      if (
        typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined"
      ) {
        var range = document.createRange();
        range.selectNodeContents(e.target);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(e.target);
        textRange.collapse(false);
        textRange.select();
      }
      $this.data("before", this.innerHTML);
    });

    $(this.$refs.edit).on("blur", function(e) {
      e.stopPropagation = true;
      e.preventDefault = true;
      const $this = $(this);
      if ($this.data("before") !== this.innerHTML) {
        $this.data("before", this.innerHTML);
        self.text = this.innerHTML;
      }
    });

    $(this.$refs.edit).on("keydown", function(e) {
      e.stopPropagation();
      if (e.which == 13) {
        if (e.ctrlKey) {
          e.preventDefault();
          e.target.blur();
          self.hasFocus = false;
        }
      }
    });
  }
};
</script>

<style scoped>
.display-static-label-box {
  display: table;
  overflow: hidden;
}

.value {
  display: table-cell;
  overflow: hidden;
  resize: none;
  z-index: auto;
  vertical-align: middle;
}

.editable {
  white-space: pre-wrap;
  caret-color: rgb(250, 42, 5);
}

.editable:focus {
  border: 0;
  outline: 0;
}

br:before {
  content: "\A";
  white-space: pre-line;
}
</style>
