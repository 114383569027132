<template>
  <div class="duration-field">
    <div class="field-container" v-if="!hasFocus" @click="edit">
      <div>
        <div class="form-group form-group-sm">
          <div class="form-control display-container">
            <div>
              <div>
                {{ value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="field-container">
      <div v-for="field in fields" :key="field.name">
        <div class="form-group form-group-sm">
          <input
            type="number"
            class="form-control text-center"
            inc="1"
            min="0"
            v-model="unit(field.name).value"
            :ref="field.name"
            :max="field.max"
            :disabled="disabled"
            @focus="onFocus($event)"
            @blur.stop="onBlur($event)"
            @keydown="onKeyDown($event)"
            style="padding-top:4px"
          />
          <div :class="legendClass" @click.stop.prevent="focusAt('days')">
            {{ $tc(unit(field.name).title, 2) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputDuration from "@/components/input-duration.vue";
export default {
  name: "SynopticDataValueDuration",
  extends: InputDuration,
  props: {
    saveOnTab: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  methods: {
    edit() {
      this.setFocus(true);
      this.$nextTick(() => {
        this.parse(this.value || 0);
        this.focusAt("days") || this.focusAt("hours");
      });
    },
    validateFocus() {
      for (var name in this.$refs) {
        if (document.activeElement == this.$refs[name][0]) {
          return true;
        }
      }
      return false;
    },
    onFocus($event) {
      if (this.hasFocus) return;
      let el = $event.target;
      this.$nextTick(() => {
        for (var name in this.$refs) {
          if (el == this.$refs[name][0]) {
            this.setFocus(true);
          }
        }
      });
    },
    onBlur() {
      this.parse();
      this.$nextTick(() => {
        setTimeout(() => {
          if (!this.validateFocus()) {
            this.setFocus(false);
          }
        }, 0);
      });
    }
  },
  mounted() {
    this.$el.blur = () => {
      this.setFocus(false);
    };
  }
};
</script>

<style scoped>
.duration-field {
  flex: 4;
  z-index: 2;
  margin: auto;
  height: inherit;
}

.field-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  height: inherit;
}

.field-container > div {
  flex-grow: 1;
  /* height: inherit; */
}

.form-group {
  position: relative;
  height: inherit;
}

.form-group > .form-control {
  padding: 0;
  min-width: 42px;
  height: inherit;
  font-size: inherit;
  background-color: transparent;
}

.form-group > input.form-control {
  padding: 10px 0 0 0;
  border-color: transparent #d2d6de transparent transparent;
}
.form-group > input.form-control:focus {
  border-color: transparent #d2d6de transparent transparent;
}

div.legend {
  position: absolute;
  top: -1px;
  width: 100%;
  text-align: center;
  color: #666;
  font-size: 12pt;
  overflow: hidden;
  z-index: 3;
}

div.legend-2 {
  font-size: 60%;
}

div.legend-3 {
  font-size: 50%;
}

div.legend-4 {
  font-size: 40%;
}

.display-container {
  border: 0;
}

.display-container > div {
  /* display: flex; */
  /* align-items: center;
  height: inherit; */
}

.display-container > div > div {
  /* flex: 1; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
