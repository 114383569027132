<template>
  <div
    class="status-icon"
    :style="style"
    v-if="currentState && currentState.img"
  >
    <div class="status-icon-container" ref="img">
      <!-- <img v-bind:src="currentState.img" class="status-image" /> -->
    </div>
  </div>
  <div class="status-icon" :style="style" v-else-if="mode == 'editor'">
    {{ (currentState && currentState.label) || "ImageList" }}
  </div>
</template>

<script>
import SynopticStatus from "@/components/synoptic/synoptic-status.vue";

export default {
  name: "SynopticStatusIcon",
  extends: SynopticStatus,
  computed: {
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    }
  },
  watch: {
    currentState: {
      handler(n) {
        let style = this?.control?.synopticComponent?.style || {};
        let bgColor =
          !n || n.backgroundColor == "transparent"
            ? style["background-color"] || "transparent"
            : n.backgroundColor;
        this.$nextTick(() => {
          if (this.$refs.img) {
            this.$refs.img.style.backgroundColor = bgColor;
            this.$refs.img.style.backgroundImage = `url(${this.currentState.img})`;
          }
        });

        this.$emit("hasContent", n !== null);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
/* status icon component */

.status-icon {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-icon-container {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: background-image 500ms ease;
}

.status-image {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.status-icon-green {
  background: #28d826;
  color: #ffffff;
}

.status-icon-red {
  background: #ef3900;
  color: #ffffff;
}

.status-icon-gray {
  background: #a7a7a7;
  color: #ffffff;
}
</style>
