<template>
  <iframe
    :id="id"
    :src="src"
    frameborder="0"
    @load="onLoad"
    ref="frame"
  ></iframe>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "IframeWrapper",
  props: {
    src: {
      type: String,
      default: "/static/common/pages/iframe-wrapper.html"
    },
    props: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      loaded: false,
      id: this.$utils.uuid()
    };
  },
  watch: {
    props: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.loaded) {
          this.send(val);
        }
      }
    }
  },
  methods: {
    async send(data) {
      this.$refs.frame.contentWindow.postMessage({
        hi_msg: "custom",
        value: JSON.parse(JSON.stringify(data))
      });
    },
    onMessage(e) {
      if (e.data.hi_msg && e.data.id == this.id) {
        this.$emit(e.data.hi_msg, e.data.value);
      }
    },
    onLoad() {
      this.loaded = true;
      this.send({
        ...this.props,
        $setup: {
          events: Object.keys(this.$listeners),
          id: this.id
        }
      });
    }
  },
  mounted() {
    window.addEventListener("message", this.onMessage.bind(this));
  }
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
}
</style>
