<template>
  <div
    class="status-label"
    :style="style"
    v-if="currentState || mode == 'editor'"
  >
    <span>
      {{ (currentState && currentState.label) || "TextList" }}
    </span>
  </div>
</template>

<script>
import SynopticStatus from "@/components/synoptic/synoptic-status.vue";
export default {
  name: "SynopticStatusLabel",
  extends: SynopticStatus,
  computed: {
    style() {
      let ctlStyle = this?.control?.synopticComponent?.style || {};
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        "justify-content": {
          left: "flex-start",
          right: "flex-end",
          center: "center"
        }[ctlStyle["text-align"] || "center"],
        // convert to flex style
        "align-items": {
          top: "flex-start",
          bottom: "flex-end",
          center: "center",
          middle: "center"
        }[ctlStyle["vertical-align"] || "top"],
        "background-color":
          !this.currentState ||
          this.currentState?.backgroundColor == "transparent"
            ? this.controlStyle["background-color"]
            : this.currentState.backgroundColor
      };
    }
  },
  watch: {
    currentState: {
      handler(n) {
        this.$emit("hasContent", n !== null);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.status-label {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.status-label span {
  width: 100%;
}
</style>
