<template>
  <div class="display-box" :style="controlStyle">
    <div class="value" :style="labelStyle">
      {{ formattedValue }}
    </div>
  </div>
</template>

<script>
import { pick, omit } from "lodash";

import SynopticEquipmentDataControlBase from "@/components/synoptic/synoptic-equipment-data-control-base.vue";
export default {
  name: "SynopticFormattedDisplayValue",
  extends: SynopticEquipmentDataControlBase,
  data() {
    return {
      wrapperStylePropeties: [
        "border",
        "border-radius",
        "background-color",
        "visibility"
      ]
    };
  },
  computed: {
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    labelStyle() {
      // merge default and temporary styles
      // omitting those reserved for wrapper
      let style = Object.assign(
        {
          "--text-size-adjust": this.$utils.iOS() ? "40%" : "80%"
        },
        this.control.synopticComponent.style,
        this.tmp?.style
      );
      style = omit(style, this.wrapperStylePropeties);
      return style;
    },
    controlStyle() {
      let ctlStyle = this?.control?.synopticComponent?.style || {};
      let style = {
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`,
        // convert to flex style
        "justify-content": {
          left: "flex-start",
          right: "flex-end",
          center: "center"
        }[ctlStyle["text-align"] || "center"],
        // convert to flex style
        "align-items": {
          top: "flex-start",
          bottom: "flex-end",
          center: "center",
          middle: "center"
        }[ctlStyle["vertical-align"] || "center"],
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        "border-radius": ctlStyle["border-radius"] ?? "3px",
        ...pick(ctlStyle, this.wrapperStylePropeties),
        ...pick(this.tmp?.style, this.wrapperStylePropeties)
      };
      // if no flex justify-content conversion was possible
      // it must be caused by a justify text-align property (hereby not covered)
      if (!style["justify-content"]) {
        style["justify-content"] = "flex-start"; // force left alignment
        style["text-align"] = "justify"; // add text-align
      }
      return style;
    }
  },
  mounted: function() {
    this.$emit("hasContent", true);
  }
};
</script>

<style scoped>
.display-box {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.display-box > .value {
  white-space: pre;
  padding: 0 5px;
  resize: none;
  z-index: auto;
  -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust);
}
</style>
