<template>
  <div class="display-box" :style="controlStyle">
    <div
      ref="edit"
      class="value"
      :class="{ editable: mode == 'editor' }"
      :contenteditable="mode == 'editor' ? true : false"
      :style="labelStyle"
      v-html="text"
    ></div>
  </div>
</template>

<script>
import { pick, omit } from "lodash";

export default {
  name: "SynopticStaticLabel",
  props: {
    control: Object,
    currentRect: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      hasFocus: false,
      wrapperStylePropeties: [
        "border",
        "border-radius",
        "background-color",
        "visibility"
      ]
    };
  },
  computed: {
    mode() {
      //return this.$store.getters['dashboard/mode']||'viewer';
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    text: {
      set(value) {
        this.control.synopticComponent.value = value;
      },
      get() {
        let value =
          this?.control?.synopticComponent?.tmp?.text ||
          this?.control?.synopticComponent?.value ||
          "";
        return value.replace(/\n/g, "<br/>").replace(/\\n/g, "<br/>"); // legacy
      }
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    labelStyle() {
      // merge default and temporary styles
      // omitting those reserved for wrapper
      let style = Object.assign(
        {},
        this.control.synopticComponent.style,
        this.tmp?.style
      );
      style = omit(style, this.wrapperStylePropeties);
      return style;
    },
    controlStyle() {
      let ctlStyle = this?.control?.synopticComponent?.style || {};
      let style = {
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`,
        // convert to flex style
        "justify-content": {
          left: "flex-start",
          right: "flex-end",
          center: "center"
        }[ctlStyle["text-align"] || "center"],
        // convert to flex style
        "align-items": {
          top: "flex-start",
          bottom: "flex-end",
          center: "center",
          middle: "center"
        }[ctlStyle["vertical-align"] || "top"],
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        "border-radius": ctlStyle["border-radius"] ?? "3px",
        ...pick(ctlStyle, this.wrapperStylePropeties),
        ...pick(this.tmp?.style, this.wrapperStylePropeties)
      };
      // if no flex justify-content conversion was possible
      // it must be caused by a justify text-align property (hereby not covered)
      if (!style["justify-content"]) {
        style["justify-content"] = "flex-start"; // force left alignment
        style["text-align"] = "justify"; // add text-align
      }
      style["-webkit-justify-content"] = style["justify-content"];
      style["-webkit-align-items"] = style["align-items"];
      if (this.$utils.iOS()) {
        style["white-space"] = "nowrap";
        style["--text-size-adjust"] = "40%";
      } else {
        style["--text-size-adjust"] = "70%";
      }
      return style;
    }
  },
  methods: {
    setFocus(value) {
      this.hasFocus = value;
    }
  },
  mounted: function() {
    let self = this;
    this.$emit("hasContent", true);
    $(this.$refs.edit).on("focus", function(e) {
      e.stopPropagation();
      self.hasFocus = true;
      const $this = $(this);
      e.target.focus();
      if (
        typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined"
      ) {
        var range = document.createRange();
        range.selectNodeContents(e.target);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(e.target);
        textRange.collapse(false);
        textRange.select();
      }
      $this.data("before", this.innerHTML);
    });

    $(this.$refs.edit).on("blur", function(e) {
      e.stopPropagation = true;
      e.preventDefault = true;
      const $this = $(this);
      if ($this.data("before") !== this.innerHTML) {
        $this.data("before", this.innerHTML);
        self.text = this.innerHTML;
      }
    });

    $(this.$refs.edit).on("keydown", function(e) {
      e.stopPropagation();
      if (e.which == 13) {
        if (e.ctrlKey) {
          e.preventDefault();
          e.target.blur();
          self.hasFocus = false;
        }
      }
    });
  }
};
</script>

<style scoped>
.display-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: visible;
  /* line-break must be explicit declared in value control property due IOS text adjusts (you might make use of 0x10 line-break)  */
  white-space: normal;
  -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust);
}

.display-box > .value {
  resize: none;
  z-index: auto;
  flex: 1;
  /* it is just an average value based on supported fonts lower/upper ratio */
}

.editable {
  caret-color: rgb(250, 42, 5);
}

.editable:focus {
  border: 0;
  outline: 0;
}

br:before {
  content: "\A";
  white-space: pre-line;
}
</style>
