<script>
import DataService from "@/services/equipment-data.js";
import SynopticEquipmentDataControl from "@/components/synoptic/synoptic-equipment-data-control.vue";

export default {
  name: "SynopticDataValueBase",
  extends: SynopticEquipmentDataControl,
  data() {
    return {
      busy: false,
      iValue: "",
      mFocus: false,
      skipRemoteUpdate: false,
      resetButton: true,
      saveButton: true,
      iAmWhoPersisted: false,
      hasActiveForm: false,
      manuallyChanged: false,
      formSaving: false
    };
  },
  computed: {
    hasLastData() {
      return this.lastData ? true : false;
    },
    value: {
      set(value) {
        // console.log(value);
        let previous = this.iValue;
        this.iValue = this.parseValue(value);
        // local data might be configured for real time update
        if (
          this.requireRealTimeUpdate &&
          this.isValid &&
          previous != this.iValue
        ) {
          this.publish();
        }
      },
      get() {
        if (this.hasFocus || this.formSaving) return this.iValue;
        return this.formattedValue;
      }
    },
    hasFocus: {
      set(value) {
        if (this.mode == "viewer") {
          this.mFocus = value;
          if (value) {
            this.skipRemoteUpdate = true;
          } else if (!this.hasChanged()) {
            this.skipRemoteUpdate = false;
          }
        } else {
          this.mFocus = false;
        }
      },
      get() {
        return this.mFocus;
      }
    },
    mode() {
      //return this.$store.getters['dashboard/mode']||'viewer';
      return this.$route.path.startsWith("/dashboard/screen")
        ? "editor"
        : "viewer";
    },
    allowDecimal() {
      // return !(
      //   (this?.control?.dataType || "").match(
      //     /(bool|coil|integer|long|status)/gi
      //   ) != null
      // );
      return (this?.lastData?.type || "").match(/(float)/gi) != null;
    },
    allowNegativeInput() {
      return !(
        (this?.control?.dataType || "").match(
          /(unsigned|bool|coil|input status)/gi
        ) != null
      );
    },
    dataId() {
      let id = "";
      if (this?.control?.synopticComponent?.expression) {
        id = this.$utils.evaluate(
          {$: this.$root.$formatter.findResource},
          this?.control?.synopticComponent?.expression
        );
      }
      return id || this?.control?.data_id || "";
    },
    references() {
      return (
        ("config" in this.$root &&
          "references" in this.$root.config &&
          this.$root.config.references) ||
        {}
      );
    },
    text_list_id() {
      return this.$root.$formatter.textListId(this?.lastData);
    },
    text_list_items() {
      let self = this;
      let lst = [];
      let text_list = self.text_list;
      if (text_list && text_list.items) {
        for (var i in text_list.items) {
          lst.push({id: i, name: text_list.items[i]});
        }
      }
      return lst;
    },
    text_list() {
      // let self = this;
      // let text_list_id = self.text_list_id;
      // let lst = (this.references.text_lists || []).filter(function(i) {
      //   return i.id == text_list_id;
      // });
      // return (lst.length && lst[0]) || null;
      return this.$root.$formatter.textList(this?.lastData);
    },
    defaultTextListValue() {
      // if (this.text_list) {
      //   for (var i in this.text_list.default_item) {
      //     return i;
      //   }
      // }
      // return "";
      return this.$root.$formatter.defaultTextListValue(this?.lastData);
    },
    hasPendingCommand() {
      return this.lastData?.pending_commands?.length > 0 ||
        this.lastData?.pending_mapping_write
        ? true
        : false;
    },
    min() {
      return this.limit((this?.control?.synopticComponent || {})["min"]);
    },
    max() {
      return this.limit((this?.control?.synopticComponent || {})["max"]);
    },
    isValid() {
      return this?.lastData && !this.error;
    },
    isDuration() {
      return this?.dataFormat?.format_mask === "duration";
    },
    isString() {
      return this?.lastData?.type.match(/(string)/gi) != null;
    },
    localError() {
      return "";
    },
    error() {
      if (!this.lastData) return "";
      if (!(this?.lastData?.id || 0)) {
        return "invalid_data";
      }
      if (this?.lastData?.current_value && this.value === "") {
        return "invalid_value";
      }
      if (this.min != undefined && parseFloat(this.value) < this.min) {
        return "out_of_range";
      }
      if (this.max != undefined && parseFloat(this.value) > this.max) {
        return "out_of_range";
      }
      return this.localError;
    },
    hasWritePermission() {
      return (
        this.$can("manage", "DadoEscrita") &&
        !this.lastData?.read_only &&
        this.hasInteractionPermission
      );
    },
    hasInteractionPermission() {
      return this.control?.synopticComponent?.interaction_permission?.length
        ? this.control.synopticComponent.interaction_permission.some((p) =>
            this.$store.getters["user/hasUserAccessTo"](p)
          )
        : true;
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    actionButtonsAlwaysVisible() {
      if (this?.control?.synopticComponent?.toolbarVisibility === undefined) {
        return this.showRestoreButton || this.showSaveButton;
      }
      return this?.control?.synopticComponent?.toolbarVisibility == "always";
    },
    showActionButtons() {
      return (
        (this.showRestoreButton || this.showSaveButton) &&
        (this.actionButtonsAlwaysVisible || this.isDirty || this.hasFocus)
      );
    },
    showRestoreButton() {
      return this?.control?.synopticComponent?.restoreButton || false;
    },
    showSaveButton() {
      return this?.control?.synopticComponent?.saveButton || false;
    },
    originalRawValue() {
      let data = {
        current_value: {
          ...(this?.lastData?.restore ?? this?.lastData?.current_value ?? null)
        }
      };
      return this.dataValueCurrentIndex < 0
        ? this.$root.$formatter.rawValue(data)
        : this.$root.$formatter.rawValue(data, this.dataValueCurrentIndex);
    },
    isDirty() {
      // if (!this.skipRemoteUpdate) return false;
      return this.manuallyChanged && this.hasChanged();
    },
    controlStyle() {
      let style = Object.assign({}, this.control.synopticComponent.style, {});
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return {
        ...style,
        "border-radius": style["border-radius"] ?? "3px",
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`
      };
    },
    controlState() {
      if (this.busy) return "busy";
      if (!this.isValid) return "error";
      if (this.hasFocus) return "focus";
      if (this.isDirty) return "dirty";
      return "default";
    },
    fgColor() {
      switch (this.controlState) {
        case "focus":
          return this?.control?.synopticComponent?.focusStyle?.color ?? "#333";
        case "error":
          return this?.control?.synopticComponent?.errorStyle?.color ?? "#333";
        case "dirty":
          return (
            this?.control?.synopticComponent?.pendingStyle?.color ?? "#666"
          );
        default:
          return this.controlStyle["color"] || "#333";
      }
    },
    bgColor() {
      switch (this.controlState) {
        case "busy":
          return "#dcdcdc";
        case "focus":
          return (
            (this?.control?.synopticComponent?.focusStyle || {})[
              "background-color"
            ] || "#ffeb3b40"
          );
        case "error":
          return (
            (this?.control?.synopticComponent?.errorStyle || {})[
              "background-color"
            ] || "#ff0000"
          );
        case "dirty":
          return (
            (this?.control?.synopticComponent?.pendingStyle || {})[
              "background-color"
            ] || "#ffeb3b1f"
          );
        default:
          return this.controlStyle["background-color"] || "transparent";
      }
    },
    isDisabled() {
      return (
        !this.lastData ||
        !this.lastData.enabled ||
        !this.lastData?.device?.enabled ||
        !this.lastData?.device?.connector?.enabled ||
        !this.hasWritePermission ||
        (this.$attrs.disabled ? true : false)
      );
    },
    inputStep() {
      if (this.isDuration || this.isString) return undefined;
      let vlr = parseInt(this.control.synopticComponent.inputStep ?? "");
      return isNaN(vlr) || vlr <= 1 ? 1 : vlr;
    },
    requireRealTimeUpdate() {
      // true  - store will only updated after successfuly save this data
      // false - store will be in sync with real time changes
      return this.control.synopticComponent.realTimeUpdate ?? false; // default is false (update after save it only)
    },
    timestamp() {
      return this?.lastData?.current_value?.date_time ?? "";
    }
  },
  watch: {
    dataId(n) {
      if (n) {
        this.restore();
      }
    },
    hasLastData: {
      handler(n, o) {
        if (!o && n) {
          this.restore();
        }
      },
      immediate: true
    },
    hasPendingCommand(n, o) {
      if (this.mode == "viewer" && !this.hasFocus && o && !n) {
        this.onPendingCommandChanged();
      }
    },
    dataValueCurrentIndex(n) {
      if (
        n == -1 ||
        !(this.memorySize > 1) ||
        !this.dataValueIndex ||
        this.hasFocus
      )
        return;
      if (this.isDirty) {
        this.value = this.originalRawValue;
      }
      // console.log(`${n} ${this.originalRawValue} ${this.isDirty}`)
    },
    timestamp(n, o) {
      if (
        this.mode != "editor" &&
        n &&
        o &&
        n != o &&
        new Date(n).toISOString() != new Date(o).toISOString()
      ) {
        this.onTimeStampChanged();
      }
      this.iAmWhoPersisted = false;
      this.formSaving = false;
    }
  },
  methods: {
    equipmentData(id) {
      return (
        (this.$store.getters["dashboard/dataList"] || []).find(
          (i) => i.id == id
        ) || null
      );
    },
    limit(entry) {
      if (!entry || typeof entry !== "object") return undefined;
      let value = "";
      if ((!entry.type || entry.type == "data") && entry.data_id) {
        let data = this.equipmentData(entry.data_id) || null;
        if (data) {
          let dataIndex = this.$root.$formatter.dataValueCurrentIndex({
            ...data,
            portal_data: {
              data_value_index: entry.data_value_index
            }
          });
          value =
            dataIndex < 0
              ? this.$root.$formatter.rawValue(data)
              : this.$root.$formatter.rawValue(data, dataIndex);
        } else {
          value = "";
        }
      } else if (
        (!entry.type ||
          entry.type == "constant" ||
          (entry.type == "data" && !entry.data_id)) &&
        entry.value !== undefined
      ) {
        value = entry.value;
      }
      if (value !== "") {
        return parseFloat(value);
      }
      return undefined;
    },
    restore(focus) {
      this.value = this.rawValue;
      this.manuallyChanged = false;
      if (focus) {
        // user asked to restore (click)
        this.$nextTick(() => {
          if (this?.$refs?.inpValue?.$el) {
            this?.$refs?.inpValue?.$el.focus();
            this.hasFocus = true;
          }
        });
      } else {
        this.dataChange();
      }
    },
    dataSamplesRequest(ids) {
      var query = {
        contract_id: this.$store.getters["user/contract_id"]
      };
      if (ids !== undefined && ids.length) {
        query.data_ids = ids.join(",");
      } else {
        if (this?.lastData?.device?.connector) {
          query.connector_id = this?.lastData?.device?.connector?.id;
        }
        if (this.$store.getters["deviceId"]) {
          query.device_id = this.$store.getters["deviceId"];
        }
      }
      return this.$store.dispatch("dashboard/fetchDataSamples", query);
    },
    updateDataSamples() {
      let dataIds;
      const connector = this?.lastData?.device?.connector;
      if (connector?.mqtt_topic_prefix) {
        // avoid fetching local data since it might be out-of-date. It will be indeed updated by a mqtt data value publish
        dataIds = this.$store.getters["dashboard/dataList"]
          .filter(
            ({id, clp_id}) =>
              parseInt(id) != this.lastData.id &&
              parseInt(this.lastData.clp_id) == parseInt(clp_id)
          )
          .map(({id}) => id);
      } else {
        // 2023-06-15 - due near fair presentation restrictions
        // all connector related values will be updated
        dataIds = [];
      }
      let delay = parseInt(
        connector?.user_data?.extended_properties
          ?.reading_data_after_write_command_timeout || 0
      );
      if (delay && !isNaN(delay)) {
        setTimeout(
          () => {
            this.$store.dispatch("dashboard/fetchDataState", {
              ids: [this.lastData.id]
            });
            this.$store.dispatch("dashboard/fetchDataAlarmsState", [
              this.lastData.id
            ]);
            this.dataSamplesRequest(dataIds);
          },
          delay,
          this
        );
      } else {
        this.$store.dispatch("dashboard/fetchDataState", {
          ids: [this.lastData.id]
        });
        this.$store.dispatch("dashboard/fetchDataAlarmsState", [
          this.lastData.id
        ]);
        this.dataSamplesRequest(dataIds);
      }
    },
    save() {
      return new Promise((resolve, reject) => {
        if (
          this.mode == "editor" ||
          !this.hasWritePermission ||
          this.busy ||
          !this.isValid ||
          (this?.lastData && this?.lastData?.read_only)
        ) {
          reject();
          return;
        }
        if (
          (this?.control?.synopticComponent?.formName ?? "") === "" &&
          this.isDirty
        ) {
          this.busy = false;
          this.iAmWhoPersisted = true;
          const fname = this.$utils.uuid();
          this.control.synopticComponent.formName = fname;
          this.$root.$emit("form:submit", fname);
          this.$nextTick(() => {
            this.control.synopticComponent.formName = "";
            if (this?.$refs?.inpValue?.$el) {
              if (this?.$refs?.inpValue?.$el?.blur) {
                this?.$refs?.inpValue?.$el.blur();
              }
              this.hasFocus = false;
              this.skipRemoteUpdate = false;
            }
            this.busy = false;
          });
        }
        resolve();
      });
    },
    dataChange() {
      let text = [];
      let ts = this?.lastData?.current_value?.date_time || "";
      ts = ts ? this.$dt.format(ts) : "";
      let vlr = this?.lastData?.current_value
        ? this?.lastData?.current_value.value
        : "";
      if (vlr !== "" && !isNaN(Number(vlr)) && this.control.format) {
        //vlr = this.$utils.sprintf(this.control.format, vlr);
        vlr = this.value;
      }

      let value = ts ? `${vlr} - ${ts}` : vlr;

      // Preenche o texto que aparece no title do componente
      if (this?.control?.synopticComponent.hint) {
        text.push(this?.control?.synopticComponent.hint);
      }

      text.push(
        `${this.$tc("data", 1)}: (${this?.lastData?.id}) ${
          this?.lastData?.name
        }`
      );
      text.push(
        `${this.$tc("device", 1)}: (${this?.lastData?.device?.id}) ${
          this?.lastData?.device?.name
        }`
      );
      text.push(
        `${this.$t("memory_type")}: ${this?.lastData?.memory_type?.name}`
      );
      text.push(`${this.$t("value")}: ${value}`);
      if (this?.lastData?.identity_embedded_app)
        text.push(
          `${this.$t("identity_embedded_app")}: ${this.lastData
            .identity_embedded_app || ""}`
        );

      // Preenche as informações de erro e desabilitação
      if (this.error) {
        text.push(this.$t(this.error));
      }
      if (this.isDisabled) {
        text.push(this.$tc("disabled"));
      }
      this.$emit("dataChange", text.join("\n"));
    },
    calcPerc(vlr, min, max) {
      let value = isNaN(Number(vlr)) ? null : parseFloat(vlr);
      if (value === null) return "0";
      let minValue = parseFloat(min === undefined ? this.min : min);
      let maxValue = parseFloat(max === undefined ? this.max : max);
      if (value < minValue) {
        value = minValue;
      } else if (value > maxValue) {
        value = maxValue;
      }
      let perc =
        (maxValue - minValue > 0
          ? (value - minValue) / (maxValue - minValue)
          : 0) * 10000;
      return Math.round(perc) / 100;
    },
    parseValue(value) {
      // TODO: move it to iValue assignment (synoptic-data-value-base)
      // once it should be validated also for real time validation.
      if (
        value !== "" &&
        !this.isDuration &&
        !this.isString &&
        this.inputStep !== undefined &&
        this.inputStep != 1
      ) {
        const vlr = parseFloat(value);
        const stp = parseFloat(this.inputStep);
        const mod = vlr % stp;
        if (mod) {
          console.log(
            `The data value was changed to ${this.value} due input step of ${stp}`
          );
        }
        return !mod ? value : mod >= stp / 2 ? stp - mod + vlr : vlr - mod;
      }
      return value;
    },
    async publish() {
      if (this?.lastData?.current_value && this.error == "") {
        let vlr = this.forecastValue();
        if (vlr != (this?.lastData?.current_value?.value ?? "")) {
          let date_time = new Date(
            this?.lastData?.current_value?.date_time
              ? new Date(this?.lastData?.current_value?.date_time).getTime() + 1
              : new Date()
          ).toISOString();
          this.$store.dispatch("dashboard/setDataValue", {
            id: this.lastData.id,
            value: vlr,
            date_time: date_time
          });
        }
      }
    },
    forecastValue() {
      let value =
        this.lastData.type == "string"
          ? this.$utils.removeDiacritics(this.iValue).replace(/[,]/g, "")
          : Number(this.iValue);
      var ix = this.dataValueCurrentIndex;
      if (ix >= 0) {
        let vlr =
          (this?.lastData?.current_value &&
          this?.lastData.current_value.value != null &&
          this?.lastData.current_value.value !== ""
            ? this?.lastData.current_value.value
            : "") ?? "";
        let lst = `${vlr}`.replace(/(^\[|\]$)/g, "").split(",");
        if (ix >= 0 && ix < lst.length) {
          lst[ix] = value;
          value = `[${lst.join(",")}]`;
        }
      }
      return value;
    },
    hasChanged() {
      let n, o; // new and old values
      if (this.isString) {
        n = this.$utils.trim(this.iValue);
        o = this.$utils.trim(this.originalRawValue);
        return n !== o;
      } else {
        n = parseFloat(this.iValue);
        o = parseFloat(this.originalRawValue);
        return (!isNaN(n) && !isNaN(o) && n != o) || (isNaN(o) && !isNaN(n));
      }
    },
    onPendingCommandChanged() {
      if (!this.iAmWhoPersisted) this.restore();
    },
    onTimeStampChanged() {
      // console.log(
      //   `${this.$options.name} ${this.isDirty} ${this.formSaving} ${this.lastData.current_value.value} ${this.iValue}`
      // );
      if (this.hasPendingCommand || this.hasFocus) return;
      if (
        (this.requireRealTimeUpdate && this.isValid) ||
        (this.isDirty && (!this.hasActiveForm || this.formSaving)) ||
        !this.isDirty
      ) {
        this.restore();
      }
    }
  },
  mounted() {
    this.$emit("hasContent", true);
  },
  beforeDestroy() {
    this.srv = null;
  }
};
</script>

<style scoped>
.busy {
  opacity: 0.5;
  cursor: wait;
}

.no-focus-dirty {
  color: #9d8787;
  background-color: #ffeb3b1f;
}
input.no-focus-dirty {
  color: inherit;
}
.input-group-addon > i {
  -webkit-text-size-adjust: 50%;
}
</style>
