<template>
  <vue-resizable
    v-if="mode == 'editor' && control.enabled"
    :class="controlClasses"
    v-bind:zoom="zoom"
    :active="active"
    v-on:resize:start="eHandler"
    v-on:resize:move="eHandler"
    v-on:resize:end="eHandler"
    v-on:drag:start="eHandler"
    v-on:drag:move="eHandler"
    v-on:drag:end="eHandler"
    v-bind:dragSelector="dragSelector"
    v-bind:fit-parent="false"
    v-bind="vueResizableRect"
  >
    <div
      v-bind:class="iclass"
      :title="`${lastData ? control.name + '\n' + title : control.name} ${
        isLocked ? '\n' + $t('titles.editing_not_allowed') : ''
      }`"
      v-on:mousedown.ctrl.exact="toggleSelection(control.id)"
      v-on:mousedown.exact="select()"
    >
      <div
        class="draggable-container"
        @dblclick="onDoubleClick"
        :style="{ 'z-index': isEditing ? 'auto' : 3 }"
      />
      <component
        :is="widget"
        v-bind:control="control"
        v-bind:equipment="equipment"
        v-bind:lastData="lastData"
        v-on:hasContent="onHasContent"
        :currentRect="controlRect"
        :zoom="zoom"
        @dataChange="onDataChange"
        ref="innerComponent"
      ></component>
    </div>
    <span
      class="fa fa-rotate-left rotate"
      v-slidable="{
        onUpdate: rotate,
        onBeginSlide: stopRecording,
        onEndSlide: resumeRecording,
        vertical: true
      }"
      @dblclick="rotation = 0"
      v-if="
        isSelected && controlClasses.selected && !isLocked && rotationEnabled
      "
    ></span>
  </vue-resizable>
  <vue-resizable
    v-else-if="isVisible && userHasViewPermission"
    :class="viewerModeClasses"
    :zoom="zoom"
    :active="[]"
    v-bind="vueResizableRect"
    :style="compatibleStyle"
  >
    <div
      :class="iclass"
      :title="title"
      v-if="!busy"
      v-on:click.capture="checkInteractionPermission"
      v-on:dblclick.capture="checkInteractionPermission"
      v-on:click.prevent.stop="onCustomEvent('click')"
      v-on:dblclick.prevent.stop="onCustomEvent('dblclick')"
    >
      <div class="draggable-container" v-if="mode == 'editor'" />
      <component
        :class="controlClasses"
        :is="widget"
        :control="control"
        :equipment="equipment"
        :lastData="lastData"
        :currentRect="controlRect"
        :zoom="zoom"
        :disabled="isCSSDisabled"
        @hasContent="onHasContent"
        @dataChange="onDataChange"
        @click="validateAndTrigger('click')"
        @dblclick="validateAndTrigger('dblclick')"
        ref="innerComponent"
      ></component>
    </div>
  </vue-resizable>
</template>

<script>
/*
  ALl new componenet must be referenced here
*/

import VueResizable from "@/components/editor/vue-resizable.vue";
import SynopticIconizedDisplayValue from "@/components/synoptic/synoptic-iconized-display-value.vue";
import SynopticImage from "@/components/synoptic/synoptic-image.vue";
import SynopticStatusIcon from "@/components/synoptic/synoptic-status-icon.vue";
import SynopticStatusLabel from "@/components/synoptic/synoptic-status-label.vue";
import SynopticFormattedDisplayValue from "@/components/synoptic/synoptic-formatted-display-value.vue";
import SynopticStaticLabel from "@/components/synoptic/synoptic-static-label.vue";
import SynopticSimpleTable from "@/components/synoptic/synoptic-simple-table.vue";
import SynopticNotAvailableLabel from "@/components/synoptic/synoptic-not-available-label.vue";
import SynopticDataValueInput from "@/components/synoptic/synoptic-data-value-input.vue";
import SynopticDataValueSlider from "@/components/synoptic/synoptic-data-value-slider.vue";
import SynopticDataValueSelect from "@/components/synoptic/synoptic-data-value-select.vue";
import SynopticDataValueToggle from "@/components/synoptic/synoptic-data-value-toggle.vue";
import SynopticDataValueCheckbox from "@/components/synoptic/synoptic-data-value-checkbox.vue";
import SynopticProgressBar from "@/components/synoptic/synoptic-progress-bar";
import SynopticGauge from "@/components/synoptic/synoptic-gauge";
import SynopticButton from "@/components/synoptic/synoptic-button.vue";
import SynopticChart from "@/components/synoptic/synoptic-chart";
import SynopticCustomControl from "@/components/synoptic/synoptic-custom-control";
import SynopticPropertyEditor from "@/components/synoptic/property-editor";

import SlidableDirection from "@/directives/slidable";
import isEqual from "lodash/isEqual";
import throttle from "lodash/throttle";

const isInputControl = (control) => {
  var ref = {
    SynopticDataValueInput: true,
    SynopticDataValueSelect: true,
    SynopticDataValueToggle: true,
    SynopticDataValueCheckbox: true,
    SynopticDataValueSlider: true
  };
  return ref[control?.synopticComponent?.componentName || ""] ? true : false;
};

// assign an expression parsed and usabled value to the monitored property
const $P = (entry, name, item, rawValueParser) => {
  let vlr;
  if (typeof item == "object") {
    if (item.memory_size > 1) {
      vlr = [...new Array(item.memory_size || 1)].map((i, ix) =>
        rawValueParser(item, ix)
      );
    } else {
      vlr = rawValueParser(item);
      vlr = vlr === true ? 1 : vlr === false ? 0 : vlr;
    }
  } else {
    vlr = item === true ? 1 : item === false ? 0 : item;
    vlr = vlr === "" ? "" : isNaN(vlr) ? vlr : parseFloat(vlr);
  }
  entry[name] = vlr;
};

// build a monitored property
const $E = (payload) => {
  var ix = payload.i;
  var obj = payload.o; // Important - do not json parset it, property get function must be present
  var parser = payload.p;
  let $v = "$" + String.fromCharCode(parseInt(ix) + 97);
  let entry = {};
  if (parser) {
    if (typeof parser == "string" && parser.indexOf("$info") > 0) {
      let descriptors = Object.getOwnPropertyDescriptors(obj);
      for (var p in descriptors) {
        if (descriptors[p].get) {
          $P(entry, `${$v}_${p}`, obj[p]);
        }
      }
    } else if (typeof parser == "function") {
      $P(entry, $v, JSON.parse(JSON.stringify(obj)), parser);
    }
  }
  return entry;
};

export { isInputControl };
export default {
  name: "SynopticControl",
  directives: { slidable: SlidableDirection },
  props: {
    equipment: { type: Object, default: null, required: false },
    zoom: {
      default: 1,
      type: [Number, String],
      required: false
    },
    control: {
      type: Object,
      required: true,
      default: null
    },
    mode: { type: String, required: true, default: "viewer" },
    isPanelEditing: { type: Boolean, required: false, default: false },
    isPanelLocked: { type: Boolean, required: false, default: false },
    isBackgroundCtrl: { type: Boolean, required: false, default: false }
  },
  components: {
    VueResizable
  },
  data() {
    return {
      hasContent: false,
      iTitle: "",
      widgetName: "SynopticNotAvailableLabel",
      widget: null,
      editing: false,
      noRecord: true,
      widgets: {
        SynopticIconizedDisplayValue,
        SynopticImage,
        SynopticStatusIcon,
        SynopticStatusLabel,
        SynopticFormattedDisplayValue,
        SynopticStaticLabel,
        SynopticSimpleTable,
        SynopticNotAvailableLabel,
        SynopticDataValueInput,
        SynopticDataValueSlider,
        SynopticDataValueSelect,
        SynopticDataValueToggle,
        SynopticDataValueCheckbox,
        SynopticProgressBar,
        SynopticButton,
        SynopticChart,
        SynopticGauge,
        SynopticCustomControl
      },
      busy: false,
      vueResizableRect: {},
      controlRect: {},
      rotationEnabled: false,
      changing: false
    };
  },
  computed: {
    equipmentDataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    extendedDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    equipmentDashboard() {
      return this.$store.getters["dashboard/dashboardEquipment"];
    },
    dataId() {
      var id = this?.control?.data_id || "";
      var exp = this?.control?.synopticComponent?.expression || "";
      if (!id && exp.indexOf(".id") >= 0 && exp.split("/").length == 3) {
        id = this.$utils.evaluate(
          { $: this.$root.$formatter.findResource },
          exp
        );
      }
      return id || "";
    },
    lastData() {
      if (!this.dataId) return null;
      let lst = isNaN(parseInt(this.dataId))
        ? this.extendedDataList
        : this.equipmentDataList;
      let data = lst.find(({ id }) => id == this.dataId) || null;
      if (!data && this?.equipmentDashboard?.base_model) {
        data =
          lst.find(
            (prop) =>
              prop?.device?.connector?.id == this?.equipmentDashboard?.id &&
              prop.name == (this.dataId + "").replace(/connector_/, "")
          ) || null;
      }
      return data;
    },
    title: {
      set(value) {
        this.iTitle = value;
      },
      get() {
        const txt =
          this?.iTitle ||
          this?.control.synopticComponent?.hint ||
          this?.lastData?.name ||
          this?.lastData?.description ||
          "";
        return `${
          this.isCSSDisabled ? this.$tc("disabled", 1) + "\n" : ""
        }${txt}`;
      }
    },
    isVisible() {
      return (
        this?.control.enabled &&
        (this?.control?.synopticComponent?.tmp?.style?.visibility || null) !=
          "hidden" &&
        (this?.control?.synopticComponent?.tmp?.style?.display || null) !=
          "none"
      );
    },
    isCSSDisabled() {
      return (
        this?.control.enabled &&
        (this?.control?.synopticComponent?.tmp?.style || {})["--hi-disable"] ==
          1
      );
    },
    isReady() {
      return this.control.data_id && !this.lastData ? false : true;
    },
    clientRect() {
      // action rect
      let rect = {
        top:
          this?.tmp?.rect?.top ||
          this?.control?.synopticComponent?.clientRect?.top,
        left:
          this?.tmp?.rect?.left ||
          this?.control?.synopticComponent?.clientRect?.left,
        width:
          this?.tmp?.rect?.width ||
          this?.control?.synopticComponent?.clientRect?.width,
        height:
          this?.tmp?.rect?.height ||
          this?.control?.synopticComponent?.clientRect?.height
      };
      return (
        { ...this?.control?.synopticComponent?.clientRect, ...rect } || {
          top: 0,
          left: 0,
          height: 40,
          width: 100
        }
      );
    },
    top() {
      return parseInt(this.clientRect.top || 0);
    },
    left() {
      return parseInt(this.clientRect.left || 0);
    },
    height() {
      return parseInt(this.clientRect.height || 40);
    },
    width() {
      return parseInt(this.clientRect.width || 100);
    },
    rotation: {
      set(value) {
        let control = JSON.parse(JSON.stringify(this.control));
        control.synopticComponent.rotation = value;
        this.$store.dispatch("synoptic/updateControl", {
          id: control.id,
          control,
          noRecord: this.noRecord
        });
      },
      get() {
        return {
          transform: `rotate(${parseInt(
            this.control.synopticComponent.rotation || 0
          )}deg)`
        };
      }
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlClasses() {
      var entry;
      if (this.mode === "editor") {
        if (this?.tmp?.classList) {
          entry = {
            resizable: true,
            selected: false,
            ...(this?.tmp?.classList || {})
          };
        } else {
          entry = {
            resizable: true,
            selected: this.isSelected,
            selectable: this.isPanelEditing
          };
        }
      } else {
        entry = {
          clicable: this.hasCustomAction && this.userHasInteractionPermission,
          invisible: !this.isVisible,
          disabled: this.isCSSDisabled,
          "control-effects": true
        };
        if (this?.tmp?.classList) {
          entry = { ...entry, ...(this?.tmp?.classList || {}) };
        }
      }

      return entry;
    },
    style() {
      return {
        width: this.width + "px",
        height: this.height + "px",
        overflow: "visible"
      };
    },
    compatibleStyle() {
      let style = {};
      if ((this?.tmp?.style || {})["z-index"]) {
        style["z-index"] = this.tmp.style["z-index"];
      }
      return style;
    },
    isVerticallyFlipped() {
      const r = this?.control?.synopticComponent?.rotation;
      return (r >= 67 && r < 157) || (r >= 247 && r < 337);
    },
    iclass() {
      let ret = {
        "synoptic-control": true,
        "vertically-flipped":
          this.isVerticallyFlipped &&
          this.clientRect.width < this.clientRect.height,
        changing: this.changing,
        notVisible: !this.hasContent && this.mode == "viewer",
        locked: this.mode == "editor" && this.isLocked && !this.isBackgroundCtrl
      };
      ret[this.id] = true;
      return ret;
    },
    viewerModeClasses() {
      if (!this.hasContent && this.mode == "viewer") {
        return "notVisible";
      }
      let cls = {
        resizable: true
      };
      const valid = {
        top: "animated-top",
        left: "animated-left",
        width: "animated-width",
        height: "animated-height"
      };
      let lst =
        this?.control?.synopticComponent?.on?.dataChange?.expression_list || [];
      let actions, params;
      for (var i in lst) {
        actions = lst[i]?.actions || [];
        for (var j in actions) {
          params = actions[j]?.options?.params || {};
          for (var p in params) {
            if (valid[p]) {
              cls[valid[p]] = true;
            }
          }
        }
      }
      if (this.isCSSDisabled) {
        cls["resizable-disabled"] = true;
      }
      return cls;
    },
    id() {
      return "ctrl" + this._uid;
    },
    isSelected() {
      return (
        this.selectedControls.findIndex(({ id }) => id == this.control.id) != -1
      );
    },
    nSelectedControls() {
      return this?.selectedControls?.length || 0;
    },
    isEditing() {
      return this.isSelected && this.editing;
    },
    userHasViewPermission() {
      let permissions =
        this.control.synopticComponent.view_permission ??
        (this.control.synopticComponent.process_area_id
          ? [this.control.synopticComponent.process_area_id]
          : []);
      return (
        !permissions.length || permissions.some((p) => this.hasUserAccessTo(p))
      );
    },
    userHasInteractionPermission() {
      return (
        !this.control.synopticComponent.interaction_permission?.length ||
        this.control.synopticComponent.interaction_permission.some((p) =>
          this.hasUserAccessTo(p)
        )
      );
    },
    selectedControls() {
      return this.$store.getters["synoptic/selectedControls"];
    },
    hasUserAccessTo() {
      return this.$store.getters["user/hasUserAccessTo"];
    },
    dataMonitorEnabled() {
      return this.mode == "editor"
        ? this.$store.getters["synoptic/dataSimulation"]
        : true;
    },
    monitoredDataList() {
      // if no logical expression is defined there will be no need to watch any data
      if (!this.dataMonitorEnabled) return [];
      let expressionList =
        (this?.control?.enabled &&
          this?.control?.synopticComponent?.on?.dataChange?.expression_list) ||
        [];
      let dataList =
        this?.control?.synopticComponent?.on?.dataChange?.data_list || [];
      if (expressionList.length && dataList.length) {
        return (
          this?.control?.synopticComponent?.on?.dataChange?.data_list || []
        ).map((i) => i.data_id);
      }
      return [];
    },
    monitoredDataValues() {
      if (!this.widget) return undefined;
      let entry = null;
      let dataIdList = this.monitoredDataList;
      if (!dataIdList.length) return entry;

      (this.$store.getters["dashboard/dataList"] || [])
        .filter((item) => dataIdList.indexOf(item.id) >= 0)
        .forEach((item) => {
          entry = {
            ...(entry || {}),
            ...$E({
              i: dataIdList.indexOf(item.id),
              o: item,
              p: this.$root.$formatter.rawValue
            })
          };
        });
      if (!entry) {
        dataIdList.forEach((prop, ix) => {
          let data = this.extendedDataList.find(({ id }) => id == prop);
          if (!data && this?.equipmentDashboard?.base_model) {
            data =
              (this.extendedDataList || []).find(
                (item) =>
                  item?.device?.connector?.id == this?.equipmentDashboard?.id &&
                  item.name == (prop + "").replace(/connector_/, "")
              ) || null;
          }
          if (data) {
            entry = {
              ...(entry || {}),
              ...$E({
                i: ix,
                o: data,
                p:
                  prop.indexOf("$info") >= 0
                    ? prop
                    : this.$root.$formatter.rawValue
              })
            };
          }
        });
      }
      return entry
        ? JSON.parse(JSON.stringify(entry, Object.keys(entry).sort()))
        : null;
    },
    hasCustomAction() {
      let events = this.control?.synopticComponent?.on || {};
      for (let customEventName in events) {
        if ((events[customEventName]?.actions || []).length) {
          return true;
        }
      }
      return false;
    },
    active() {
      return !this.isChangeEnabled || this.nSelectedControls > 1
        ? []
        : ["r", "rb", "b", "lb", "l", "lt", "t", "rt"];
    },
    dragSelector() {
      return !this.isChangeEnabled ? null : ".draggable-container";
    },
    isLocked() {
      return (
        !this.isPanelEditing ||
        this.isPanelLocked ||
        this.control.locked ||
        this.control.linked
      );
    },
    isChangeEnabled() {
      return !this.isLocked && (this.selectToChange ? this.isSelected : true);
    },
    selectToChange() {
      return this.$store.getters["synoptic/selectToChange"];
    },
    cid() {
      let sid = "";
      if (!isInputControl(this.control)) {
        sid = this.$store.getters["dashboard/dataValueSync"];
      }
      return `${this._uid}${sid}`;
    }
  },
  watch: {
    isReady(n) {
      if (n) {
        this.setWidget(this.widgetName);
      }
    },
    monitoredDataValues: {
      handler(n, o) {
        if (n !== undefined) {
          this.onMonitoredDataValuesChanged(n, o || {});
        }
      },
      deep: true,
      immediate: true
    },
    style: {
      deep: true,
      handler(n, o) {
        if (
          isEqual(
            JSON.parse(JSON.stringify(n || {})),
            JSON.parse(JSON.stringify(o || {}))
          )
        )
          return; // it avoids firefox rendering it twice (it seems that after zoom be applied, firefox shifts already rotated controls)
        this.busy = true;
        this.$nextTick(() => {
          this.busy = false;
        });
      }
    },
    clientRect: {
      deep: true,
      handler(n, o) {
        if (
          isEqual(
            JSON.parse(JSON.stringify(n) || {}),
            JSON.parse(JSON.stringify(o) || {})
          )
        )
          return; // it avoids firefox rendering it twice (it seems that after zoom be applied, firefox shifts already rotated controls)
        this.recalculateSelectionRect();
        this.controlRect = Object.assign(
          {},
          this.control.synopticComponent.clientRect
        );
      }
    },
    "rotation.transform": ["recalculateSelectionRect"],
    isVisible(n, o) {
      if (!o && n) {
        this.recalculateSelectionRect();
      }
    }
  },
  methods: {
    triggerEventActions(eventName, actions) {
      let self = this;
      actions.forEach((action) => {
        self.$root.$emit("custom_action", {
          context: self,
          source: this.control,
          event: eventName,
          connector: self.equipment,
          action: action
        });
      });
    },
    resetTmpStyle() {
      if (this.tmp) {
        this.$set(this.control.synopticComponent, "tmp", null);
      }
    },
    onMonitoredDataValuesChanged(n, o) {
      try {
        if (!this?.control?.enabled || !this.monitoredDataList.length || !n)
          return;
        if (isEqual(n, o)) return;
        let evt = this?.control?.synopticComponent?.on?.dataChange || null;
        let pos = -1;
        for (var i in evt.expression_list || []) {
          let exp = evt.expression_list[i].expression;
          if (exp) {
            if (this.$utils.isTrue(exp.replace(/\./g, "_"), n)) {
              pos = i;
              break;
            }
          }
        }
        this.resetTmpStyle();
        if (pos >= 0) {
          this.triggerEventActions(
            "dataChange",
            evt.expression_list[pos].actions
          );
        }
      } catch (e) {
        //console.log(e);
      }
    },
    onCustomEvent(name) {
      if (this.mode != "viewer" || this.isCSSDisabled) return;
      let evt = (this.control?.synopticComponent?.on || {})[name] || null;
      if (evt?.actions?.length && this.hasUserAccessTo(evt.process_area_id)) {
        if (evt?.confirmation?.enabled) {
          this.$swal(evt.confirmation).then((confirm) => {
            if (confirm) {
              this.triggerEventActions(name, evt.actions);
            }
          });
        } else {
          this.triggerEventActions(name, evt.actions);
        }
      }
    },
    onDoubleClick($event) {
      if (this.mode != "editor" || this.control.linked) return;
      this.editing = !this.editing;
      if (this.editing) {
        this.$nextTick(() => {
          if (
            this?.$refs?.innerComponent.$el &&
            this?.$refs?.innerComponent.$el.click
          ) {
            // this.$refs.innerComponent.$el.click({ clientX: $event.clientX, clientY: $event.clientY });
            this.$refs.innerComponent.$el.dispatchEvent(
              new MouseEvent("click", {
                bubbles: false,
                cancelable: true,
                clientX: $event.clientX,
                clientY: $event.clientY
              })
            );
          }
          this.$root.$emit("synoptic:tab", "synoptic-component");
        });
      }
    },
    onDataChange(details) {
      this.iTitle = details;
      this.onMonitoredDataValuesChanged(this.monitoredDataValues, {});
    },
    onHasContent(option) {
      this.hasContent = option;
      this.editing = false;
    },
    setWidget(name) {
      if (!this.widget || this.widget == SynopticNotAvailableLabel) {
        this.widget = this.widgets[name];
        this.editing = false;
      }
    },
    eHandler(data) {
      if (!this.isPanelEditing) return;
      this.editing = false;
      switch (data.eventName) {
        case "resize:start":
          {
            this.changing = true;
            this._initialRect = this._lastRect = {
              width: data.width,
              height: data.height
            };
            // this.$store.dispatch("synoptic/selectControl", this.control.id);
            const _select = async () => {
              this.select();
            };
            _select();
          }
          break;
        case "resize:move":
          this.delayedControlRectUpdate(data);
          break;
        default:
          if (this.selectedControls.length > 1) {
            switch (data.eventName) {
              case "drag:start":
                this.changing = true;
                this._dragPos = { left: data.left, top: data.top };
                break;
              case "drag:move":
                this.selectedControls.forEach((control) => {
                  if (control.locked) return;
                  let clientRect = {
                    top:
                      control.synopticComponent.clientRect.top +
                      (Math.round(data.top) - Math.round(this._dragPos.top)),
                    left:
                      control.synopticComponent.clientRect.left +
                      (Math.round(data.left) - Math.round(this._dragPos.left)),
                    width: control.width,
                    height: control.height
                  };
                  this.$store.dispatch("synoptic/updateControl", {
                    id: control.id,
                    control: {
                      synopticComponent: {
                        clientRect: {
                          ...clientRect
                        }
                      }
                    },
                    noRecord: true
                  });
                });
                this._dragPos = { left: data.left, top: data.top };
                break;
              case "drag:end":
                // it must dispatch an update event
                this.changing = false;
                if (
                  !this._dragPos ||
                  (data.left == this._dragPos?.left &&
                    data.top == this._dragPos?.top)
                )
                  break;
                this.selectedControls.forEach((control) =>
                  this.$store.dispatch("synoptic/updateControl", {
                    id: control.id,
                    control,
                    forceRecord: true
                  })
                );
            }
          } else {
            if (["drag:end", "resize:end"].includes(data.eventName)) {
              let clientRect = {};
              this.changing = false;
              if (data.eventName == "drag:end") {
                clientRect.top = Math.round(data.top);
                clientRect.left = Math.round(data.left);
              } else if (data.eventName == "resize:end") {
                clientRect.top = Math.round(data.top);
                clientRect.left = Math.round(data.left);
                let { widthDiff, heightDiff } = this.getDimensionDifference(
                  data,
                  this._initialRect
                );
                clientRect.width = Math.max(
                  Math.round(this.width + widthDiff),
                  0
                );
                clientRect.height = Math.max(
                  Math.round(this.height + heightDiff),
                  0
                );
              }
              this.$store.dispatch("synoptic/updateControl", {
                id: this.control.id,
                control: {
                  synopticComponent: {
                    clientRect: {
                      ...clientRect
                    }
                  }
                }
              });
            }
          }
      }
    },
    select() {
      if (!this.isPanelEditing || this.control.linked) return;
      this.editing = false;
      this.$root.$emit("controlSidebar:setContent", SynopticPropertyEditor);
      this.$store.dispatch("synoptic/selectControl", this.control.id);
      this.$nextTick(() => {
        // wait synopticpropertyeditor be mounted in order to handle this control as widget
        this.rotationEnabled = false;
        this.$nextTick(() => {
          this.rotationEnabled = true;
          this.$root.$emit("editor.keyboard:focus");
        });
      });
    },
    clearSelectedControls() {
      this.$store.dispatch("synoptic/clearSelectedControls");
    },
    addToSelection(controlId) {
      this.$store.dispatch("synoptic/addToSelection", controlId);
    },
    removeFromSelection(controlId) {
      this.$store.dispatch("synoptic/removeFromSelection", controlId);
    },
    toggleSelection(controlId) {
      if (!this.isPanelEditing || this.control.linked) return;
      if (this.isSelected) {
        this.removeFromSelection(controlId);
      } else {
        this.addToSelection(controlId);
      }
    },
    rotate(direction) {
      let value = (this.control.synopticComponent.rotation || 0) + direction;
      value = value >= 360 ? value - 360 : value < 0 ? 360 + value : value;
      this.rotation = value;
    },
    stopRecording() {
      this.noRecord = true;
      this.$store.dispatch("synoptic/setRotating", true);
    },
    resumeRecording() {
      this.noRecord = false;
      this.$store.dispatch("synoptic/updateControl", {
        id: this.control.id,
        control: this.control,
        forceRecord: true
      });
      this.recalculateSelectionRect();
      setTimeout(() => this.$store.dispatch("synoptic/setRotating", false), 0);
    },
    // sets vue-resizable width/height to the real rect of the element
    async recalculateSelectionRect() {
      await this.$nextTick();
      const r = this?.control?.synopticComponent?.rotation;
      if (r && (r <= 158 || r >= 202)) {
        let rect = this.$refs.innerComponent?.$el?.getBoundingClientRect?.();
        if (rect) {
          this.vueResizableRect = {
            width: this.clientRect.height,
            height: this.clientRect.width,
            top: this.top,
            left: this.left,
            opacity: 0.5
          };
        }
      } else {
        this.vueResizableRect = {
          width: this.width,
          height: this.height,
          top: this.top,
          left: this.left,
          opacity: 0.5
        };
      }
    },
    getDimensionDifference(rect, oldRect) {
      let widthDiff, heightDiff;
      if (this.isVerticallyFlipped) {
        widthDiff = rect.height - oldRect.height;
        heightDiff = rect.width - oldRect.width;
      } else {
        widthDiff = rect.width - oldRect.width;
        heightDiff = rect.height - oldRect.height;
      }
      return { widthDiff, heightDiff };
    },
    delayedControlRectUpdate: throttle(function (data) {
      let { widthDiff, heightDiff } = this.getDimensionDifference(
        data,
        this._lastRect
      );
      this.controlRect = {
        width: Math.max(Math.round(this.controlRect.width + widthDiff), 0),
        height: Math.max(Math.round(this.controlRect.height + heightDiff), 0),
        left: Math.round(data.left),
        top: Math.round(data.top)
      };
      this._lastRect = { width: data.width, height: data.height };
    }, 5),
    checkInteractionPermission(e) {
      if (!this.canInteract()) {
        e.stopPropagation();
      }
    },
    validateAndTrigger(event) {
      if (this.canInteract()) {
        this.onCustomEvent(event);
      }
    },
    canInteract() {
      return (
        !this.control.synopticComponent.interaction_permission?.length ||
        this.control.synopticComponent.interaction_permission.some((p) =>
          this.hasUserAccessTo(p)
        )
      );
    }
  },
  mounted() {
    this.widgetName =
      (this.control &&
        this.control.synopticComponent &&
        this.control.synopticComponent.componentName) ||
      this.widgetName;
    this.setWidget(this.widgetName);
    this.recalculateSelectionRect();
    this.controlRect = Object.assign(
      {},
      this.control.synopticComponent.clientRect
    );
  },
  created() {
    this.resetTmpStyle();
  }
};
</script>

<style scoped>
.resizable {
  position: absolute;
}

.resizable-disabled {
  /* z-index: inherit; */
  cursor: not-allowed;
}

.synoptic-control {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vertically-flipped {
  flex-direction: row;
}

.changing {
  overflow: hidden;
  opacity: 0.4;
}

.changing::v-deep > div {
  border-color: transparent !important;
}

.synoptic-control:hover {
  z-index: 1;
}

.synoptic-control.locked {
  opacity: 0.7;
}

.synoptic-control.locked:hover {
  cursor: not-allowed;
}

.draggable-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

.notVisible {
  display: none !important;
}

.selected {
  outline: 2px solid #00c0ef;
  /* outline-offset: -1px; */
}

.selected:hover {
  outline: 2px solid #00c0ef !important;
  /* outline-offset: -1px !important; */
}

.selectable:hover {
  outline: 2px solid rgb(221, 154, 78);
  /* outline-offset: -1px; */
  cursor: pointer;
}

.clicable:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.274);
}

.disabled,
.disabled:hover {
  opacity: 0.6;
  cursor: not-allowed;
}

.control-effects {
  transition-timing-function: ease;
  transition-property: background-color;
  transition-duration: 1s;
}

.rotate {
  position: absolute;
  color: #00c0ef;
  top: calc(50% - 6px);
  right: -18px;
  font-size: 1em;
}

.invisible {
  /*   
  in order to preserve inner dimension, it does not use display: none  
  ** the notVisible class is already defined for such purpose ** 
  */
  visibility: hidden;
}
.animated-top {
  transition: top 0.2s ease-out 0s;
}
.animated-left {
  transition: left 1s ease-out 0s;
}
.animated-width {
  transition: width 1s ease 0s;
}
.animated-height {
  transition: height 1s ease 0s;
}
</style>
