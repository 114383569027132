<template>
  <div class="display-static-label-box">
    <div
      class="
    display-static-label-box-value
    text-center
    text-warning
    "
      title="Not Available"
    >
      <span class="value" style="">
        <i class="fa fa-ban"></i>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SynopticNotAvailableLabel",
  data() {
    return {};
  },
  props: {
    control: Object,
    lastData: Object,
    isReady: Boolean,
    relativeProportions: Object
  },
  methods: {},
  computed: {},
  mounted: function() {
    this.$emit("hasContent", true);
  }
};
</script>

<style scoped>
.display-static-label-box {
  display: table;
  width: 100%;
  vertical-align: middle;
}

.synaptic-control .display-static-label-box .display-static-label-box-value {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 5px;
}
</style>
