<template>
  <div class="me" v-if="control.synopticComponent" :style="style">
    <div
      :class="{
        clicable: hasWritePermission && this.lastData && this.lastData.enabled,
        disabled: isDisabled
      }"
      v-on:click.prevent.stop="onClick"
      :style="inputStyle"
    >
      <i class="fa fa-check-square-o" v-if="isChecked"></i>
      <i class="fa fa-square-o" v-else></i>
      <span class="text">{{ label }}</span>
    </div>
    <SynopticDataValueTip
      :entry="lastData"
      :value="value"
      :error="error"
      :position="control.synopticComponent.commandStatus"
      :hasPendingCommand="hasPendingCommand"
    />
  </div>
</template>

<script>
import SynopticDataValueBase from "./synoptic-data-value-base.vue";
import SynopticDataValueTip from "./synoptic-data-value-tip.vue";
export default {
  name: "SynopticDataValueCheckbox",
  extends: SynopticDataValueBase,
  components: {
    SynopticDataValueTip
  },
  computed: {
    isChecked() {
      return (
        parseInt(this.value) ==
        parseInt(this?.control?.synopticComponent?.values?.checked || 0)
      );
    },
    label() {
      return this.control.synopticComponent.label || "";
    },
    localError() {
      if (
        this.values.length &&
        this?.lastData?.current_value &&
        this.values.indexOf(Number(this.value)) == -1
      ) {
        return this.$t("invalid_value") + " (" + this.value + ")";
      }
      return "";
    },
    values() {
      return Object.values(
        this?.control?.synopticComponent?.values || {}
      ).map((i) => Number(i));
    },
    inputStyle() {
      if (!this.isValid && this.control.synopticComponent.errorStyle) {
        return {
          color: this.control.synopticComponent.errorStyle.color ?? "black"
        };
      }
      return {};
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px"
      };
    }
  },
  watch: {
    // timestamp(n, o) {
    //   if (
    //     this.mode != "editor" &&
    //     n &&
    //     o &&
    //     n != o &&
    //     new Date(n).toISOString() != new Date(o).toISOString()
    //   ) {
    //     if (
    //       (!this.hasPendingCommand && !this.hasFocus && this.isValid) ||
    //       (!this.hasFocus && !this.isDirty && !this.hasPendingCommand)
    //     ) {
    //       this.restore();
    //     }
    //   }
    // }
  },
  methods: {
    // onClick() {
    //   if (this.busy || this.isDisabled) return;
    //   this.value = this.isChecked
    //     ? this.control.synopticComponent.values.unchecked
    //     : this.control.synopticComponent.values.checked;
    //   this.skipRemoteUpdate = true;
    //   this.save();
    // }
    onClick() {
      if (this.busy || this.isDisabled) return;
      this.skipRemoteUpdate = true;
      let prv = this.value;
      let vlr = this.isChecked
        ? this.control.synopticComponent.values.unchecked
        : this.control.synopticComponent.values.checked;
      this.manuallyChanged = this.rawValue != vlr;
      this.value = vlr;
      this.save()
        .then(() => {})
        .catch(() => {
          this.value = prv; // restore previous value if something went wrong
        });
    },
    onPendingCommandChanged() {
      // important: keep this method empty to avoid overwriting the value
    }
  }
};
</script>

<style scoped>
.me {
  z-index: inherit;
  display: table;
  vertical-align: middle;
  height: inherit;
  width: inherit;
}

.me > div {
  display: table-cell;
  vertical-align: middle;
  width: inherit;
}

.text {
  font-size: 95%;
  padding-left: 10px;
}

.clicable:hover {
  opacity: 0.6;
  cursor: pointer;
}

.disabled:hover {
  cursor: not-allowed;
}
</style>
